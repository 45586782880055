import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { WelcomeComponent } from './welcome/welcome.component';
import { DestinationComponent } from './destination/destination.component';
import { CardComponent } from './destination/card/card.component';
import { VillaComponent } from './villa/villa.component';
import { SlideShowComponent } from './villa/slide-show/slide-show.component';
import { FooterComponent } from './footer/footer.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.translationUrl, '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    DestinationComponent,
    CardComponent,
    VillaComponent,
    SlideShowComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModulesPro.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent]
})
export class AppModule { }
