<header class="h-100" *ngIf="translationLoader.translation">
  <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar favstay"
              [containerInside]="false">
    <mdb-navbar-brand>
      <a routerLink="./">
        <img width="90" src="{{translationLoader.translation.header.logo}}" alt="">
      </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">
        <li *ngFor="let destination of getActiveDestinations()" class="nav-item waves-light" mdbWavesEffect
            routerLinkActive="active">
          <a class="nav-link" routerLink="/destination/{{destination.url.toLowerCase()}}">
            {{destination.name}}
          </a>
        </li>
      </ul>
    </links>
  </mdb-navbar>

  <router-outlet (activate)="onActivate($event)"></router-outlet>
</header>
<!--Main Navigation-->

<app-footer></app-footer>
