import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from '../translation-loader.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(public translationLoader: TranslationLoaderService) { }

  ngOnInit() {
  }

}
