<!-- Grid row -->
<div class="row">
  <!-- Grid column -->
  <div class="col-lg-5 col-xl-4">

    <!-- Featured image -->
    <div *ngIf="card.image" class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
      <img class="img-fluid" [src]="card.image">
      <a>
        <div class="mask rgba-white-slight"></div>
      </a>
    </div>

  </div>
  <!-- Grid column -->

  <!-- Grid column -->
  <div class="col-lg-7 col-xl-8">

    <!-- Post title -->
    <h3 class="font-weight-bold mb-3"><strong>{{card.name}}</strong></h3>

    <h6 *ngIf="card.icon" class="font-weight-bold mb-3 {{card.icon.color}}">
      <i class="{{card.icon.image}}"></i>
      {{card.icon.caption}}
    </h6>
    <!-- Excerpt -->
    <p class="dark-grey-text">{{card.description}}</p>
    <!-- Read more button -->
    <a *ngIf="card.villaurl"
       [routerLink]="['./', card.villaurl]"
       mdbBtn color="primary"
       mdbWavesEffect>
      {{card.button}}
    </a>
  </div>
  <!-- Grid column -->

</div>
<!-- Grid row -->
