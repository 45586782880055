import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { TranslationLoaderService } from './translation-loader.service';

declare let ga: (...params: any) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public translationLoader: TranslationLoaderService,
              private router: Router) {
    ga('create', environment.ga, 'auto');
  }

  ngOnInit(): void {
    this.translationLoader.changeTranslation('en');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  getActiveDestinations() {
    return this.translationLoader.destinations.filter(e => e.active && e.active === 'true');
  }
}
