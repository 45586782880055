import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { DestinationComponent } from './destination/destination.component';
import { VillaComponent } from './villa/villa.component';

const routes: Routes = [
  { path: 'welcome', component: WelcomeComponent },
  { path: 'destination/:dest', component: DestinationComponent },
  { path: 'destination/:dest/:villa', component: VillaComponent },
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: '**', redirectTo: 'welcome' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
