import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from '../translation-loader.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss']
})
export class DestinationComponent implements OnInit {
  params$: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translationLoader: TranslationLoaderService) {
  }

  ngOnInit() {
    this.params$ = this.route.params.subscribe(params => {
      this.translationLoader.setDestination(params.dest);
      if (!this.translationLoader.destination) {
        this.router.navigate(['./welcome']);
      }
    });
  }

}
