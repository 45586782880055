<!-- Intro Section -->
<main class="py-3">
  <div class="container">
    <app-slide-show *ngIf="villa.carousel"
              [carousel]="villa.carousel">
    </app-slide-show>

    <div class="row">
      <div class="col-6 small">
        <!-- Section heading -->
        <h4 class="h4-responsive font-weight-bold text-center">Property Details</h4>
        <!-- Section description -->
        <ul class="list-unstyled">
          <li class="row pb-2">
            <div class="col-2 font-weight-bold">Location</div>
            <div class="col">{{villa.details.location}}</div>
          </li>
          <li class="row pb-2">
            <div class="col-2 font-weight-bold">Bedrooms</div>
            <div class="col">{{villa.details.bedrooms}}</div>
          </li>
          <li class="row pb-2">
            <div class="col-2 font-weight-bold">Guests</div>
            <div class="col">{{villa.details.guests}}</div>
          </li>
          <li class="row pb-2">
            <div class="col-2 font-weight-bold">Attractions</div>
            <div class="col">{{villa.details.attractions}}</div>
          </li>
        </ul>
      </div>
      <div class="col-6 small">
        <!-- Section heading -->
        <h4 class="h4-responsive font-weight-bold text-center">Booking Details</h4>
        <!-- Section description -->
        <ul class="list-unstyled">
          <li class="row pb-2">
            <div class="col-3 font-weight-bold">Rental Condition</div>
            <div class="col">{{villa.conditions.rental}}</div>
          </li>
          <li class="row pb-2">
            <div class="col-3 font-weight-bold">Cancellation Policy</div>
            <div class="col">{{villa.conditions.cancellation}}</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <mdb-image-modal [modalImages]="images" type="margin"></mdb-image-modal>
    </div>

  </div>
</main>
