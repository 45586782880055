<!-- Footer -->
<footer *ngIf="translationLoader.translation" class="page-footer font-small">

  <div style="background-color: #48bba8;">
    <div class="container">

      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <h6 class="mb-0">Get connected with us on social networks!</h6>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 col-lg-7 text-center text-md-right">

          <!-- Facebook -->
          <a class="fb-ic" href="https://www.facebook.com/FavStay" target="_blank">
            <mdb-icon fab icon="facebook" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="tw-ic" href="https://twitter.com/favstay" target="_blank">
            <mdb-icon fab icon="twitter" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Linkedin -->
          <a class="li-ic" href="https://www.linkedin.com/in/favstay-pte-ltd-a17a47109/" target="_blank">
            <mdb-icon fab icon="linkedin" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Instagram-->
          <a class="ins-ic" href="https://www.instagram.com/favstay/" target="_blank">
            <mdb-icon fab icon="instagram" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Weibo -->
          <a class="ins-ic" href="https://www.weibo.com/p/1006065981908608" target="_blank">
            <mdb-icon fab icon="weibo" class="white-text mr-4"></mdb-icon>
          </a>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row-->

    </div>
  </div>

  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row mt-3 dark-grey-text">

      <!-- Grid column -->
      <div class="col-md-3 col-lg-4 col-xl-3 mb-4">

        <!-- Content -->
        <h6 class="text-uppercase font-weight-bold">favstay.com</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>{{translationLoader.translation.footer.description}}</p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Products</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p *ngFor="let p of translationLoader.translation.footer.products">
          <a class="dark-grey-text" href="{{p.url}}" target="_blank">{{p.name}}</a>
        </p>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Useful links</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p *ngFor="let l of translationLoader.translation.footer.links">
          <a class="dark-grey-text" href="{{l.url}}" target="_blank">{{l.name}}</a>
        </p>
      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contact</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p *ngFor="let c of translationLoader.translation.footer.contacts">
          <i class="{{c.icon}}"></i> {{c.name}}
        </p>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center text-black-50 py-3">© 2019 Copyright:
    <a class="dark-grey-text" href="https://www.favstay.com/" target="_blank"> FavStay.com</a>
  </div>
  <!-- Copyright -->

</footer>
