<!-- Main -->
<div class="view intro-2"
     [ngStyle]="{background: 'url(\'' + translationLoader.header.background + '\') no-repeat center'}"
     [style.height]="'100vh'"
     [style.backgroundSize]="'cover'">
  <div class="full-bg-img">
    <div class="mask rgba-bg flex-center">
      <div class="container text-center white-text wow fadeInUp">
        <h2>{{translationLoader.welcome.title}}</h2>
        <br>
        <p>
          {{translationLoader.welcome.description}}
        </p>
        <br>

        <div class="btn-group" role="group" aria-label="Basic example">
          <button *ngFor="let destination of getActiveDestinations()"
                  routerLink="/destination/{{destination.url.toLowerCase()}}"
                  mdbBtn
                  type="button"
                  rounded="true"
                  outline="true"
                  class="waves-light"
                  mdbWavesEffect>
            {{destination.name}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /.Main -->
