<section class="section extra-margins pb-3 text-center text-lg-left wow fadeIn" data-wow-delay="0.3s"
         style="animation-name: none; visibility: visible;">

  <mdb-card cascade="true" wider="true" reverse="true" class="my-4 pt-5">
    <!--Card image-->
    <div class="view view-cascade overlay waves-light" mdbWavesEffect>
      <mdb-carousel class="carousel slide" [animation]="'slide'">
        <mdb-carousel-item *ngFor="let image of carousel.images">
          <div class="view w-100">
            <img class="d-block w-100" [src]="image">
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </mdb-card>

  <!-- Section heading -->
  <h4 class="h4-responsive font-weight-bold text-center">{{ carousel.title }}</h4>
  <!-- Section description -->
  <p class="text-center dark-grey-text w-responsive mx-auto">
    {{ carousel.description }}
  </p>

</section>
