import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from '../translation-loader.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  welcome: any;
  menu: any;

  constructor(public translationLoader: TranslationLoaderService) {
  }

  ngOnInit() {
    this.welcome = this.translationLoader.welcome;
    this.menu = this.translationLoader.header.menus;
  }

  getActiveDestinations() {
    return this.translationLoader.destinations.filter(e => e.active && e.active === 'true');
  }
}
