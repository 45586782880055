<!-- Intro Section -->
<main class="py-5">
  <div class="container">
    <!-- Section: Blog v.3 -->
    <section class="my-4" *ngFor="let card of translationLoader.destination.cards">
      <app-card [card]="card"></app-card>

      <hr class="my-5">
    </section>
    <!-- Section: Blog v.3 -->
  </div>
</main>
