import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationLoaderService } from '../translation-loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-villa',
  templateUrl: './villa.component.html',
  styleUrls: ['./villa.component.scss']
})
export class VillaComponent implements OnInit, OnDestroy {
  params$: Subscription;
  villa: any;
  images: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translationLoader: TranslationLoaderService) {
  }

  ngOnInit() {
    this.params$ = this.route.params.subscribe(params => {
      this.translationLoader.setDestination(params.dest);
      this.villa = this.translationLoader.destination.villas.find(e => e.url === params.villa);
      if (!this.villa) {
        this.router.navigate(['./welcome']);
      }
      this.images = this.getImages();
    });
  }

  getImages() {
    const images = [];
    this.villa.gallery.forEach(image => {
      images.push({
        img: image,
        thumb: image
      });
    });

    return images;
  }

  ngOnDestroy(): void {
    this.params$.unsubscribe();
  }

}
